<template>
  <div class="tool-page">
    <Nav />
    <div class="tool-wrap">
      <div class="card-list">
        <div v-for="(item, index) in cardList" :key="index" class="card">
          <router-link :to="item.link">
            <div class="img-box">
              <img :src="item.imgUrl" alt="" :class="item.size" />
            </div>
            <div class="text">
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <Footer class="footer" />
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import Footer from "../../component/footer.vue";
export default {
  name: "TOOL",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      cardList: [
        {
          size: "full",
          link: "/hanconfig",
          name: "Han Configurator",
          imgUrl: require("../../image/tool-hancconfig.png"),
        },
        {
          size: "full",
          link: "/myharting",
          name: "MyHarting",
          imgUrl: require("../../image/tool-myharting.png"),
        },
        {
          size: "small",
          link: "/eshop",
          name: "eShop",
          imgUrl: require("../../image/eshop.png"),
        },
        {
          size: "small",
          link: "/customer",
          name: "在线客服",
          imgUrl: require("../../image/customer.png"),
        },
      ],
      imgSize: "full",
    };
  },
};
</script>
<style lang="less" scoped>
.tool-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #cdd7de;
  .tool-wrap {
    padding: 90px 18px 220px 18px;
    .card-list {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .card {
        margin-bottom: 16px;
        width: 160px;
        min-height: 52px;
        border-radius: 24px;
        border: 1px solid #fff;
        background: #fff;
        overflow: hidden;
        .img-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 160px;
          background: @card-bg;
          img {
            object-fit: contain;
          }
        }
        .full {
          width: 100%;
          height: 100%;
        }
        .small {
          width: 110px;
          height: 116px;
        }
        .text {
          padding: 16px 10px;
          color: #212027;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 54px;
    left: 0;
  }
}
</style>
